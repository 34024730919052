import React from 'react';
import { Tr } from '../colaboradores/VacacionesViews/styled';
import moment from 'moment';
import {
	faTrashAlt,
  } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import colors from '../../constans/colors';

const TableProcedimientosBody = ({ data = [], deleteLegal, verificacionPermiso}) => {
	
  return (
    <>
      {data.map((info,index) => (
        <Tr
          key={index}
        >
          	<td 
		 		style={{paddingTop:'22px' }}
			>{moment(info.fecha).format('DD/MM/YYYY')}</td>
			<td 
		 		style={{ paddingTop:'22px' }}
			>{info.juzgado}</td>
            <td 
		 		style={{ paddingTop:'22px' }}
			>{info.expediente}</td>
            <td 
		 		style={{ paddingTop:'22px' }}
			>{info.tipo}</td>
            <td 
		 		style={{ paddingTop:'22px' }}
			>{info.actor}</td>
            <td 
		 		style={{ paddingTop:'22px' }}
			>{info.demandado}</td>
      { verificacionPermiso === 'write' && (
			<td style={{ paddingTop:'22px' }}>
		  	<a 
          href='#' 
          onClick={(e) => deleteLegal(e,index)} 
          style={{textDecoration:'none',color:colors.tashColor}}
				>
				<FontAwesomeIcon 
					color={colors.tashColor}
					icon={faTrashAlt} 
				/>
				</a>
			</td>)}
      </Tr>
      ))}
    </>
  );
};

export default TableProcedimientosBody;
